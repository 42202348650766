import configLocal from "./config.local";
import configDev from "./config.dev";
import configUat from "./config.uat";
import configProduction from "./config.production";

export function getConfig() {
  let config: any = configDev;
  const env = process.env.REACT_APP_STAGE || "";
  if (env.includes("local")) {
    config = configLocal;
  } else if (env.includes("uat")) {
    config = configUat;
  } else if (env.includes("prod")) {
    config = configProduction;
  }
  return config;
}

export default getConfig();
