import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../../_core/components/layout/Main";
import UITable from "../../_core/components/ui/UITable";
import UIButton from "../../_core/components/ui/UIButton";
import { useApiAlerts, useTableControls } from "../../_core/hooks";
import UIBack from "../../_core/components/ui/UIBack";
import { formatDate, humanize } from "../../_core/utils";
import { useSendWelcomeEmail, useUsers } from "../hooks";
import { useIdentityUser } from "../hooks/auth";
import { getHubUserJwt } from "../api";
import { UserLite } from "../api/types";
import { canImpersonate } from "../utils";

export const UsersContainer = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const controls = useTableControls();
  const { data: identityUser } = useIdentityUser();
  const [impersonatingUuid, setImpersonatingUuid] = useState<string | null>(
    null
  );
  const [impersonatingError, setImpersonatingError] = useState<any>();
  const queryParams = new URLSearchParams(location.search);
  const accountId = queryParams.get("accountId");
  const accountCode = queryParams.get("accountCode");

  if (!accountId || !accountCode) {
    history.push(accountCode ? `/?search=${accountCode}` : "/");
  }

  const { data, isFetching, isError, error } = useUsers(
    {
      page: controls.page,
      size: controls.rowsPerPage,
      accountId: accountId || "",
    },
    { enabled: Boolean(accountId) }
  );

  const {
    data: isEmailSuccess,
    mutate: executeSendEmail,
    error: emailError,
    isError: isEmailError,
  } = useSendWelcomeEmail();

  useApiAlerts(
    isEmailSuccess ? "Welcome email sent" : undefined,
    error || emailError || impersonatingError,
    isError || isEmailError
  );

  const impersonateHubUser = async (user: UserLite) => {
    setImpersonatingUuid(user.id);
    try {
      const data = await getHubUserJwt(user);
      if (data.access_token && data.hub_url && data.sso_url) {
        const url = `${data.hub_url}/jwt-login?jwt=${data.access_token}&sso_url=${data.sso_url}`;
        window.open(url, "_blank");
      }
    } catch (e) {
      setImpersonatingError(e);
    } finally {
      setImpersonatingUuid(null);
      setImpersonatingError(null);
    }
  };

  return (
    <Main
      title={`Users (${accountCode})`}
      data-testid="users-container"
      action={{
        label: "Add User",
        startIcon: <AddIcon />,
        onClick: () =>
          history.push({
            pathname: "/users/new",
            state: { accountId, accountCode },
          }),
      }}
    >
      <div className={classes.root}>
        <Grid item>
          <UIBack label="Back To Accounts" />
        </Grid>
        <UITable
          className={classes.table}
          isLoading={isFetching}
          columns={[
            "Name",
            "Email",
            "User Access",
            "Last Activity",
            "Validated",
            "Status",
            "",
            {
              name: "",
              options: {
                display: canImpersonate(identityUser),
              },
            },
          ]}
          data={data?.content}
          rowCallback={(u) => [
            u.name,
            u.email,
            u.userAccess,
            formatDate(u.lastActivity),
            u.validated ? "Yes" : "No",
            humanize(u.status || ""),
            u.validated ? (
              " "
            ) : (
              <UIButton
                data-testid="resend-btn"
                onClick={() => executeSendEmail(u)}
                className={classes.resend}
              >
                Resend Welcome Email
              </UIButton>
            ),
            <UIButton
              data-testid="impersonate-btn"
              color="secondary"
              isLoading={impersonatingUuid === u.id}
              onClick={() => impersonateHubUser(u)}
            >
              Masquerade
            </UIButton>,
          ]}
          options={{
            ...controls,
            count: data?.totalElements,
          }}
        />
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 8,
    "& table tr td:last-child": {
      textAlign: "right",
    },
  },
  table: {
    paddingTop: 22,
  },
  resend: {
    minWidth: 180,
  },
}));

export default UsersContainer;
