import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from ".";

Sentry.init({
  dsn: config.configSentry?.dsn,
  environment: process.env.REACT_APP_STAGE,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
