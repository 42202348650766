import actions from "./Actions";
import { Action } from "./Store";

const Reducer = (state: any, action: Action) => {
  switch (action.type) {
    case actions.SET_ERROR:
      return {
        ...state,
        success: "",
        alert: "",
        error: action.payload,
      };
    case actions.SET_SUCCESS:
      return {
        ...state,
        error: "",
        alert: "",
        success: action.payload,
      };
    case actions.SET_ALERT:
      return {
        ...state,
        success: "",
        error: "",
        alert: action.payload,
      };
    case actions.SET_BRAND:
      return {
        ...state,
        brand: action?.payload,
      };
  }
};

export default Reducer;
