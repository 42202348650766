import React from "react";
import { Backdrop } from "@mui/material";
import UILoader from "./ui/UILoader";

function LoadingOverlay() {
  return (
    <Backdrop open invisible>
      <UILoader color="inherit" />
    </Backdrop>
  );
}

export default LoadingOverlay;
