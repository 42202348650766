import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { AlertProps, Alert } from "@mui/material";

function UIAlert({ children, severity = "error", ...rest }: AlertProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Alert variant="filled" severity={severity} {...rest}>
        {children}
      </Alert>
    </div>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
  })
);

export default UIAlert;
