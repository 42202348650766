import React, { ReactNode } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useBrandName } from "../../../brand/hooks";

interface PublicProps {
  children: ReactNode;
  title?: string | ReactNode;
  isLoading?: boolean;
}

function Public({ title, isLoading, children, ...rest }: PublicProps) {
  const classes = useStyles();
  const brandName = useBrandName();

  React.useEffect(() => {
    document.title = `${brandName} Portal ${
      typeof title === "string" ? "- " + title : ""
    }`;
  }, [title, brandName]);

  return (
    <div className={classes.root} {...rest}>
      {children}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100vw",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
  })
);

export default Public;
