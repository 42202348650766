import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ButtonProps, Button, CircularProgress } from "@mui/material";

interface Props extends ButtonProps {
  isLoading?: boolean;
}

function UIButton({ isLoading, ...props }: Props) {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      className={[classes.root, props.className].join(" ")}
      startIcon={isLoading && <CircularProgress color="inherit" size={18} />}
      data-testid="ui-button"
      {...props}
    >
      {props.children}
    </Button>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {},
  })
);

export default UIButton;
