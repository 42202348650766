import React, { useEffect } from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Alert, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useStore } from "../hooks";

export default function Alerts() {
  const { state } = useStore();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const DEFAULT_DURATION = 6000;

  useEffect(() => {
    if (state?.error || state?.success || state?.alert) {
      setOpen(true);
    }
  }, [state]);

  const handleClose = (event?: any, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  if (state?.error) {
    // show error snackbar
    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.error.duration || DEFAULT_DURATION}
      >
        <Alert
          variant="filled"
          severity="error"
          data-testid="alert-error"
          onClose={handleClose}
        >
          {state.error.message}
        </Alert>
      </Snackbar>
    );
  }
  if (state?.success) {
    // show success snackbar
    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.success.duration || DEFAULT_DURATION}
      >
        <Alert
          variant="filled"
          severity="success"
          data-testid="alert-success"
          onClose={handleClose}
        >
          {state.success.message}
        </Alert>
      </Snackbar>
    );
  }
  if (state?.alert) {
    // show generic snackbar
    return (
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        autoHideDuration={state.alert.duration || DEFAULT_DURATION}
      >
        <Alert
          variant="filled"
          severity="info"
          data-testid="alert"
          onClose={handleClose}
        >
          {state.alert.message}
        </Alert>
      </Snackbar>
    );
  }
  return null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      marginTop: "60px",
      right: "13px",
    },
  })
);
