import React, { ChangeEventHandler, useMemo, useState } from "react";
import {
  CircularProgress,
  debounce,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import SearchIcon from "@mui/icons-material/Search";

interface Props extends OutlinedInputProps {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  isLoading?: boolean;
  placeholder?: string;
  className?: string;
  debounceMs?: number;
}

function UISearch({
  className,
  isLoading,
  value,
  onChange,
  placeholder,
  debounceMs = 400,
  ...props
}: Props) {
  const classes = useStyles();
  const [search, setSearch] = useState(value);

  const searchFunc = useMemo(
    () => debounce(onChange, debounceMs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(event.target.value);
    searchFunc(event);
  };

  return (
    <OutlinedInput
      id="search-input"
      data-testid="search-input"
      placeholder={placeholder ?? "Search"}
      startAdornment={
        <InputAdornment position="start">
          {isLoading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <SearchIcon color="action" />
          )}
        </InputAdornment>
      }
      onChange={(ev) => handleChange(ev)}
      value={search}
      className={`${classes.root} ${className}`}
      {...props}
    />
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& input": {
        padding: 11,
        paddingLeft: 4,
      },
    },
  })
);

export default UISearch;
