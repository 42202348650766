import api from "../../_core/api";
import { Account, GetAccountsParams, GetAccountsResp } from "./types";

export const getAccounts = async (params: GetAccountsParams) => {
  const { data }: { data: GetAccountsResp } = await api({
    method: "GET",
    url: "/accounts",
    params,
  });
  return data;
};

export const getAccountById = async (id: string) => {
  const { data }: { data: Account } = await api({
    method: "GET",
    url: `/accounts/${id}`,
  });
  return data;
};
