import { useQuery } from "react-query";
import { AjaxResponse } from "../../_core/api/types";
import { getBrand } from "../api";
import { Brand } from "../api/types";
import affinityLogo from "../../../images/logo-light.png";

export function useBrand(options?: any) {
  return useQuery<Brand, AjaxResponse>(["brand"], () => getBrand(), {
    ...options,
  });
}

export function useBrandColors(defaultColors: any) {
  const { data: brand } = useBrand();
  if (brand)
    return {
      primary: brand?.primary,
      secondary: brand?.secondary,
      tertiary: brand?.tertiary,
      primary_text: brand?.primaryText,
      secondary_text: brand?.secondaryText,
      tertiary_text: brand?.tertiaryText,
    };
  return defaultColors;
}

export function useBrandName() {
  const { data: brand } = useBrand();
  return brand?.name || "Hub Partner";
}

export function useLogo() {
  const { data: brand } = useBrand();
  return brand?.logo || affinityLogo;
}

export function useFavicon() {
  const { data: brand } = useBrand();
  return brand?.favicon || affinityLogo;
}
