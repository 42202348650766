import { AjaxError } from "../../_core/api/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  assignUser,
  createUser,
  getExistingUser,
  getUsers,
  sendWelcomeEmail,
} from "../api";
import {
  AssignUserParams,
  CreateUserParams,
  ExistingUser,
  GetExistingUserResp,
  GetUsersParams,
  GetUsersResp,
  UserLite,
} from "../api/types";

export function useUsers(params: GetUsersParams, options?: any) {
  return useQuery<GetUsersResp, AjaxError>(
    ["users", params],
    () => getUsers(params),
    { ...options }
  );
}

export function useExistingUser(email: string, options?: any) {
  return useQuery<GetExistingUserResp | null, AjaxError>(
    ["existingUser", email],
    () => getExistingUser(email),
    { ...options }
  );
}

export function useCreateUser(options?: any) {
  const queryClient = useQueryClient();
  return useMutation<string, AjaxError, CreateUserParams>(
    ["createUser"],
    createUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
      ...options,
    }
  );
}

export function useAssignUser(options?: any) {
  const queryClient = useQueryClient();
  return useMutation<ExistingUser, AjaxError, AssignUserParams>(
    ["assignUser"],
    assignUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      },
      ...options,
    }
  );
}

export function useSendWelcomeEmail(options?: any) {
  return useMutation<boolean, AjaxError, UserLite>(
    ["welcomeEmail"],
    sendWelcomeEmail,
    { ...options }
  );
}
