import React from "react";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Button, ButtonProps } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

export interface Props {
  label?: string;
  btnProps?: ButtonProps;
  onClick?: () => void;
}

export const UIBack = ({ label, onClick, btnProps }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<any>();
  const text =
    label ??
    "Back" + (location.state?.from ? ` To ${location.state?.from}` : "");
  return (
    <Button
      variant="text"
      color="primary"
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          history.goBack();
        }
      }}
      className={classes.button}
      {...btnProps}
    >
      <ArrowBackIos fontSize="small" /> {text}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {},
  })
);

export default UIBack;
