import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./modules/_core/config";
import { AuthConfig } from "./modules/users/api/types";

declare global {
  interface Window {
    authConfig: AuthConfig;
  }
}

if (config.configSentry?.enabled && config.configSentry?.dsn) {
  require("./modules/_core/config/sentry");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
