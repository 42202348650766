import { logout } from "../api/identityAPI";

interface Props {
  clientId: string;
}

export function SsoLogout({ clientId }: Props) {
  logout(clientId);
  return null;
}
