import React from "react";
import MUIDataTable, { MUIDataTableProps } from "mui-datatables";
import makeStyles from "@mui/styles/makeStyles";
import { isEmpty } from "../../utils";
import UILoader from "./UILoader";
import NA from "../NA";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  title?: string;
  data?: MUIDataTableProps["data"];
  rowCallback?: (val: any) => any;
  isLoading?: boolean;
  page?: number;
  rowsPerPage?: number;
  columns?: MUIDataTableProps["columns"];
  options?: MUIDataTableProps["options"];
}

function UITable({
  columns,
  data,
  options,
  title,
  rowCallback,
  page,
  rowsPerPage,
  isLoading,
  ...rest
}: Props) {
  const classes = useStyles();
  return (
    <div
      {...rest}
      className={[classes.root, rest.className].join(" ")}
      data-testid="table"
    >
      <MUIDataTable
        title={title || ""}
        data={tableDataParser(data, rowCallback, isLoading)}
        columns={columns || []}
        options={{
          pagination: true,
          download: false,
          elevation: 2,
          page: page,
          rowsPerPage: rowsPerPage || 25,
          rowsPerPageOptions: [25, 50, 100],
          print: false,
          responsive: "standard",
          selectToolbarPlacement: "none",
          filter: false,
          sort: false,
          search: false,
          serverSide: true,
          selectableRows: "none",
          rowHover: true,
          setTableProps: () => ({ size: "small" }),
          viewColumns: false,
          ...options,
        }}
      />
    </div>
  );
}

export const tableDataParser = (
  rows: any[] = [],
  rowCallback = (val: any) => val,
  isLoading?: boolean
) => {
  if (isLoading) return [[<UILoader />]];
  return (
    rows.map((row: any) => {
      const val = rowCallback?.(row)?.map?.((v: any) => {
        if (isEmpty(v)) return <NA />;
        return v;
      });
      return val;
    }) || []
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& th": {
      paddingTop: "12px",
      paddingBottom: "15px",
      borderBottom: "1px solid #d3d2d7",
    },
  },
}));

export default UITable;
