import React, { createContext, Dispatch, useReducer } from "react";
import { Brand } from "../../brand/api/types";
import Reducer from "./Reducer";

export interface AppAlert {
  message?: string;
  duration?: number;
}

export interface Action {
  type: string;
  payload?: any;
  skipStorage?: boolean;
}

export interface StoreState {
  error: AppAlert | null;
  success: AppAlert | null;
  alert: AppAlert | null;
  brand: Brand | null;
}

const initialState: StoreState = {
  error: null,
  success: null,
  alert: null,
  brand: null,
};

const Store = ({ children }: any) => {
  const [state, dispatch]: [any, Dispatch<Action>] = useReducer(
    Reducer,
    initialState
  );
  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export const StoreContext = createContext<any>(initialState);
export default Store;
