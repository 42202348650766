import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Typography, TypographyProps } from "@mui/material";

function UIText(props: TypographyProps) {
  const classes = useStyles();
  return (
    <Typography
      className={classes.root}
      variant="body1"
      color="textPrimary"
      {...props}
    >
      {typeof props.children === "string" ? (
        props.children
      ) : (
        <div>{props.children}</div>
      )}
    </Typography>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {},
  })
);

export default UIText;
