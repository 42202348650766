import { getAccounts } from "../api";
import { useQuery } from "react-query";
import { AjaxResponse } from "../../_core/api/types";
import { GetAccountsParams, GetAccountsResp } from "../api/types";

export function useAccounts(params: GetAccountsParams, options?: any) {
  return useQuery<GetAccountsResp, AjaxResponse>(
    ["accounts", params],
    () => getAccounts(params),
    {
      ...options,
    }
  );
}
