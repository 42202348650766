import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { login, getUser } from "../api/identityAPI";
import { IdentityUser } from "../api/types";

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [user, setUser] = React.useState<IdentityUser | null | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    let isMounted = true;
    const checkAuthentication = async () => {
      getUser().then((user) => {
        if (isMounted) {
          setUser(user);
          setLoading(false);
        }
      });
    };
    checkAuthentication();
    return () => {
      isMounted = false;
    };
  }, []);

  if (!loading && (user === null || user?.expired)) {
    login();
    return <div />;
  } else if (!loading && user !== null) {
    return <Route {...rest}>{children}</Route>;
  } else {
    return <div />;
  }
};
