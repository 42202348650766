import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UIAvatar from "../ui/UIAvatar";

interface MainProps {
  handleDrawerToggle: () => void;
}

function AppHeader({ handleDrawerToggle }: MainProps) {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    history.push("/logout");
  };

  return (
    <AppBar position="fixed" className={classes.appBar} data-testid="app-bar">
      <Toolbar className={classes.toolbar}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <div>
          <div
            data-testid="menu-appbar"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            className={classes.userName}
          >
            <UIAvatar textOnly />
            <ExpandMoreIcon color="inherit" />
          </div>
          <Menu
            id="menu-appbar"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            className={classes.menu}
          >
            <MenuItem onClick={handleLogout} className={classes.menuItem}>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.grey[100],
      flexGrow: 1,
      maxHeight: "64px",
      overflow: "hidden",
      color: theme.palette.text.primary,
    },
    menuButton: {
      marginRight: "auto",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: {
      justifyContent: "flex-end",
      paddingRight: "10px",
    },
    userName: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: "bold",
      color: "inherit",
    },
    menu: {
      marginTop: "35px",
    },
    menuItem: {
      fontSize: "14px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  })
);

export default AppHeader;
