import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Public from "./layout/Public";
import { useHubUserExist } from "../../users/hooks/auth";
import UIText from "./ui/UIText";
import UIAlert from "./ui/UIAlert";

const NoUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const [timer, setTimer] = useState<number>(20);
  const { data: doesHubUserExist, isFetchedAfterMount } = useHubUserExist();

  const handleLogout = useCallback(() => {
    history.push("/logout");
  }, [history]);

  useEffect(() => {
    if (isFetchedAfterMount && doesHubUserExist) {
      history.push("/");
    }
  }, [history, isFetchedAfterMount, doesHubUserExist]);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((old: number) => Math.max(old - 1, 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!timer && !doesHubUserExist) {
      handleLogout();
    }
  }, [handleLogout, timer, doesHubUserExist]);

  return (
    <Public data-testid="nouser-page" title="No User Access">
      <Grid item>
        <UIAlert severity="info">
          You do not have access, please contact your administrator
        </UIAlert>
      </Grid>
      <Grid item className={classes.logoutButton}>
        <Button
          variant="text"
          onClick={handleLogout}
          data-testid="disabled-page-logout-button"
        >
          Logout
        </Button>
      </Grid>
      <Grid item className={classes.countdown}>
        <UIText variant="caption">Logging out in {timer} seconds</UIText>
      </Grid>
    </Public>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutButton: {
      textAlign: "center",
      lineHeight: "60px",
    },
    countdown: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default NoUser;
