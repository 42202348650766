import api from "../../_core/api";
import { Brand } from "./types";

export const getBrand = async () => {
  const { data }: { data: Brand } = await api({
    method: "GET",
    url: "/brand",
    is_brand: true,
  });
  return data;
};
