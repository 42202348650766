import React, { MouseEventHandler, ReactNode } from "react";
import { TypographyProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ToolbarMenu from "./AppHeader";
import NavMenu, { drawerWidth } from "./SideMenu";
import Footer from "./Footer";
import UIText from "../ui/UIText";
import UILoader from "../ui/UILoader";
import UIButton from "../ui/UIButton";
import { useBrandName } from "../../../brand/hooks";

interface MainProps {
  children: ReactNode;
  title?: string | ReactNode;
  titleProps?: TypographyProps;
  isLoading?: boolean;
  action?: {
    label?: string;
    startIcon?: ReactNode | Element;
    endIcon?: Element;
    onClick?: MouseEventHandler<HTMLButtonElement>;
  };
}

function Main({
  title,
  titleProps,
  isLoading,
  children,
  action,
  ...rest
}: MainProps) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const brandName = useBrandName();

  React.useEffect(() => {
    document.title = `${brandName} Portal ${
      typeof title === "string" ? "- " + title : ""
    }`;
  }, [title, brandName]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const showTitle = Boolean(title);
  const showAction = Boolean(action);
  const showTitleBar = showTitle || showAction;

  return (
    <div className={classes.root} {...rest}>
      <NavMenu
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div className={classes.main} data-testid="main">
        <ToolbarMenu handleDrawerToggle={handleDrawerToggle} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {showTitleBar && (
            <div className={classes.titleBar}>
              {showTitle && (
                <UIText
                  variant="h1"
                  className={classes.title}
                  data-testid="title"
                  {...titleProps}
                >
                  {title}
                </UIText>
              )}
              {showAction && (
                <UIButton
                  className={classes.action}
                  data-testid="main-action-button"
                  startIcon={action?.startIcon}
                  endIcon={action?.endIcon}
                  onClick={action?.onClick}
                >
                  {action?.label}
                </UIButton>
              )}
            </div>
          )}
          {isLoading && <UILoader overlay />}
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    main: {
      backgroundColor: "#f0f4f8",
      flexGrow: 1,
      overflow: "hidden",
      maxWidth: `calc(100vw - ${drawerWidth}px)`,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
      },
    },
    toolbar: theme.mixins.toolbar,
    content: {
      padding: theme.spacing(3),
      backgroundColor: "#ffffff",
      width: "100%",
      height: "100%",
      minHeight: "calc(100vh - 72px)",
    },
    titleBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginBottom: theme.spacing(3),
    },
    title: {
      height: "40px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "70%",
    },
    action: {
      marginLeft: "auto",
    },
  })
);

export default Main;
