import axios from "axios";
import {
  renewTokens,
  logout,
  getUser,
  COGNITO_TOKEN_ISSUER_URL,
} from "../../auth/api/identityAPI";
import config from "../config";
import { sleep } from "../utils";
import {
  AjaxErrorConfig,
  AjaxInstance,
  AjaxRequestConfig,
  IdValueQuery,
} from "./types";

const ajax: AjaxInstance = axios.create();

const init = () => {
  ajax.defaults.baseURL = config.configEndpoints.baseEndpoint;
  ajax.defaults.headers.post["Content-Type"] = "application/json";
  ajax.defaults.headers.put["Content-Type"] = "application/json";
  ajax.interceptors.request.use(requestIntercept, (error) =>
    Promise.reject(error)
  );
  ajax.interceptors.response.use((success) => success, responseFailIntercept);
  return ajax;
};

const requestIntercept = async (request: AjaxRequestConfig) => {
  const user = await getUser();

  // Without Advanced Security turned on (costs around $4k/month), Cognito can neither adjust Access Token scopes nor claims.
  // Hence, using ID token instead, to avoid excessive costs.
  // See https://auth0.com/blog/id-token-access-token-what-is-the-difference
  // See https://aws.amazon.com/blogs/security/how-to-customize-access-tokens-in-amazon-cognito-user-pools
  // TODO Remove the Token Issuer URL fork once Affinity SSO tenants are migrated to Cognito
  const isCognitoTokenIssuer = window.authConfig?.tokenIssuerUrl.startsWith(
    COGNITO_TOKEN_ISSUER_URL
  );
  if (!request?.skip_bearer_injection) {
    if (isCognitoTokenIssuer && user?.id_token) {
      request.headers.Authorization = `Bearer ${user?.id_token}`;
    } else if (!isCognitoTokenIssuer && user?.access_token) {
      request.headers.Authorization = `Bearer ${user?.access_token}`;
    }
  }

  if (request.is_hub || request.is_brand) {
    request.baseURL = request.baseURL?.replace("/partner", "");
  }

  if (request.serialize) {
    request.paramsSerializer = function (params) {
      const parsed = new URLSearchParams();
      for (let key of Object.keys(params)) {
        if (key === "queries") {
          params.queries.forEach((q: IdValueQuery) =>
            parsed.append(q.id, q.value)
          );
        } else if (params[key] !== undefined) {
          const isArray = Array.isArray(params[key]);
          parsed.append(key, isArray ? params[key].toString() : params[key]);
        }
      }
      return parsed.toString();
    };
  }
  return request;
};

const responseFailIntercept = async (error: AjaxErrorConfig) => {
  const original: AjaxRequestConfig = error.config;
  const response = error.response;

  if (original.skip_token_refresh || response?.status !== 401) {
    return Promise.reject(response?.data);
  }

  if (typeof renewTokens === "function") {
    if (isRenewing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({
          request: original,
          resolve,
          reject,
        });
      });
    }
    isRenewing = true;

    try {
      await renewTokens();
      await sleep(500); // @hack - renewTokens() occasionally resolves early
      processQueue();
      isRenewing = false;
      return new Promise((resolve) => {
        resolve(
          ajax({
            ...original,
            skip_token_refresh: true,
          })
        );
      });
    } catch (error) {
      isRenewing = false;
      return logout(window.authConfig?.partnerPortalClientId);
    }
  }
  return Promise.reject(response.data);
};

let isRenewing = false;
let failedQueue: {
  request: AjaxRequestConfig;
  resolve: any;
  reject: any;
}[] = [];

const processQueue = () => {
  failedQueue.forEach((promise) => {
    if (promise.request) {
      axios(promise.request)
        .then((resp) => promise.resolve(resp))
        .catch((err) => promise.reject(err));
    }
  });
  failedQueue = [];
};

export default init();
